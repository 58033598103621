import {getConfig} from '../system/support/helpers';

export default {
    /**
     * The base api url
     */
    apiUrl: getConfig('API_URL', 'http://localhost'),

    projectName: "Inventory Management"
};
